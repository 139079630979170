<template>
  <main>
    <transition name="fade">
      <router-view/>
    </transition>
    <section id="features" class="features">
      <div class="container">

        <div>
          <p>
            <b>Please Note:</b>
            The product images displayed here are intended as a guide only as material delivered may vary in colour, shape and consistency
            whilst still meeting their applicable standards. Best effort has been made to supply photographs which are representative of the products characteristics.<br/>
            <br/>
            Colour in particular can vary significantly without impacting the products performance in its intended application.
            If colour is important to your application please stress this at the time of ordering. Whilst Mt Marrow will make its best effort to deliver as requested,
            the nature of quarrying means that we are unable to guarantee the colour of products delivered.
          </p>
        </div>

      </div>
    </section>
  </main>
</template>
